import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Layout, Row, Space, Card, Progress, Divider } from 'antd';
import _ from 'lodash';
import { navigate } from "gatsby"

import mbtiTest from '../../mbti_test';

const { Content, Footer } = Layout;

const questions = mbtiTest[0].questions;
const mbtiResult = mbtiTest[0].results

const IndexPage: React.FC = () => {
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState({
    'E': 0,
    'I': 0,
    'S': 0,
    'N': 0,
    'T': 0,
    'F': 0,
    'J': 0,
    'P': 0,
  });

  const resultCaculator = () => {
    let final_result = answers;
    console.log(final_result);
    // for creating an array which contains VS between types ex.["EI", "SN", "TF", "JP"]
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    let _which_type_arr = [];
    for (let k = 0; k < questions.length; k++) {
      _which_type_arr.push(questions[k].which);
    }
    _which_type_arr = _which_type_arr.filter(onlyUnique);

    // get max value & type from Each VS
    let final_type = '';
    for (let i = 0; i < _which_type_arr.length; i++) {
      let first_type = _which_type_arr[i][0]
      let second_type = _which_type_arr[i][1]
      let type_arr = [first_type, second_type]
      let max_val = 0
      // for split in case of odd | even questions in the same which(ex. EI/SN..)
      if (final_result[first_type] !== final_result[second_type]) {
        max_val = Math.max(final_result[first_type], final_result[second_type])
        // eslint-disable-next-line
        type_arr.filter(item => final_result[item] === max_val).forEach(item => final_type += item)
      } else {
        final_type += type_arr[0]
      }
    }

    // return 'THE' result TYPE from TESTS.js
    for (let z = 0; z < mbtiResult.length; z++) {
      if (final_type === mbtiResult[z].type) {

        console.log(mbtiResult[z].query)
        // navigate(`/mbti/${mbtiResult[z].query}`)
        window.location.href = `https://www.16personalities.com/ko/%EC%84%B1%EA%B2%A9%EC%9C%A0%ED%98%95-${mbtiResult[z].query.toLowerCase()}`
      }
    }
  }

  return (
    <Content style={{
      WebkitUserSelect: 'none',
      userSelect: 'none',
    }}>
      <Helmet>
        <title>내모나 - 내가모르는나</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        <link type="text/css" rel="stylesheet" href={"/css/layout.css"} />
        <link rel="apple-touch-icon" sizes="57x57" href="/icon/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/icon/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/icon/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/icon/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/icon/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/icon/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/icon/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/icon/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/icon/apple-icon-180x180.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/icon/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/icon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/icon/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/icon/favicon-16x16.png" />
        <link rel="manifest" href="/icon/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/icon/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />

        <meta name="author" content="내모나 - 내가모르는나" />
        <meta name="description" content="세상의 모든 MBTI, 내모나 나를 알아가고 나를 찾아주세요." />

        <meta property="og:title" content="내모나 - 내가모르는나" />
        <meta property="og:description" content="세상의 모든 MBTI, 내모나 나를 알아가고 나를 찾아주세요." />
        <meta property="og:image" content="https://mbti.nuca.io/images/meta-image.png" />

        {/* <script async src="https://www.googletagmanager.com/gtag/js?id=UA-127746049-3"></script> */}
        {/* <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          
            gtag('config', 'UA-127746049-3');
          `}
        </script> */}
      </Helmet>
      <Row justify="center" align="middle" style={{ paddingTop: '35%' }}>
        {step < 12 ? <Card title={questions[step].question} style={{ width: '80%' }}>
          <div>{questions[step].answers.map((answer, i) => {
            return <div key={i} onClick={() => {
              if (step <= questions.length - 1) {
                let _step = step + 1;
                let _answer_obj = Object.assign({}, answers);
                _answer_obj[answer.type] = _answer_obj[answer.type] + 1;
                setAnswers(_answer_obj);
                if (_step === 12) {
                  resultCaculator();
                }
                setStep(_step);
              }
            }}>
              <Card.Grid style={{
                width: '100%',
                paddingTop: 30,
                paddingBottom: 30,
                textAlign: 'center',
              }}
                hoverable={false}
              >
                {i == 0 ? 'A' : 'B'}. {answer.content}
              </Card.Grid>
            </div>
          })}
          </div>
        </Card> : <div>완료</div>}
        <Divider />
      </Row>
      <Row justify="center" align="middle" style={{
        position: 'fixed',
        width: '100%',
        bottom: 60
      }}>
        <div>{step + 1} / {questions.length}</div>
        <Progress percent={((step + 1) / questions.length) * 100} showInfo={false} />
      </Row>
    </Content >
  )
}

export default IndexPage;