function range(start: number, stop?: number, step?: any) {
  console.log('range', start, stop, step)
  if (typeof stop == 'undefined') {
    // one param defined
    stop = start;
    start = 0;
  }

  if (typeof step == 'undefined') {
    step = 1;
  }

  if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
    return [];
  }

  var result = [];
  for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
    result.push(i);
  }

  return result;
};

var ITEMS = [
  // persoanl
  {
    info: {
      mainTitle: "퍼스널 테스트",
      subTitle: "나는 어떤 성향일까?",
      mainUrl: "personal",
      scoreType: "MBTI",
      lang: "kr"
    },
    questions: [
      {
        which: "EI",
        question: '처음 보는 친구들에게 나는?',
        answers: [
          {
            type: "E",
            score: 2,
            content: '내가 먼저 말을 건낸다.'
          },
          {
            type: "I",
            score: 5,
            content: '대부분 다른 친구가 먼저 말을 건낸다. '
          },
        ],
      },
      {
        which: "EI",
        question: "주말에 난?",
        answers: [
          {
            type: "E",
            score: 2,
            content: "사람들과 만나는 것을 즐긴다."
          },
          {
            type: "I",
            score: 5,
            content: "혼자 있는 게 싫지 않은 편이다."
          },
        ]
      },
      {
        which: "EI",
        question: "친구들과 놀 때 나는?",
        answers: [
          {
            type: "E",
            score: 2,
            content: "왁자지껄한 신나는 분위기를 좋아한다."
          },
          {
            type: "I",
            score: 5,
            content: "소수의 친구들과 소소하게 대화하는 것을 좋아한다. "
          },
        ]
      },
      {
        which: "SN",
        question: "내가 더 중요하게 생각하는 것은?",
        answers: [
          {
            type: "S",
            score: 2,
            content: "현재가 없으면 미래도 없다."
          },
          {
            type: "N",
            score: 5,
            content: "미래를 생각하지 않으면 발전이 없다."
          },
        ]
      },
      {
        which: "SN",
        question: "일할 때 나는?",
        answers: [
          {
            type: "S",
            score: 2,
            content: "남들이 하는 대로 따라 가는 것이 좋다."
          },
          {
            type: "N",
            score: 5,
            content: "스스로 나만의 방법을 만드는 게 좋다. "
          },
        ]
      },
      {
        which: "SN",
        question: "주변 사람들이 나에게 하는 말은?",
        answers: [
          {
            type: "S",
            score: 2,
            content: "꾸준하고 참을성이 있다는 말을 자주한다."
          },
          {
            type: "N",
            score: 5,
            content: "창의적이고 독창적이라는 말을 자주한다."
          },
        ]
      },
      {
        which: "TF",
        question: "거절해야 하는 상황이 오면 나는?",
        answers: [
          {
            type: "T",
            score: 2,
            content: "강력하게 할 수 없다고 말한다."
          },
          {
            type: "F",
            score: 5,
            content: "고민하다가 대부분 들어주는 편이다."
          },
        ]
      },
      {
        which: "TF",
        question: "화났을 때 나는?",
        answers: [
          {
            type: "T",
            score: 2,
            content: "논리적으로 잘 말하면서 따진다. "
          },
          {
            type: "F",
            score: 5,
            content: "할말이 많지만 너무 분해서 눈물부터 난다."
          },
        ]
      },
      {
        which: "TF",
        question: "고민을 얘기하는 친구, 듣다 보니 친구의 잘못인 것 같다. 그럴 때 나는?",
        answers: [
          {
            type: "T",
            score: 2,
            content: "친구의 잘못된 점을 말해준다."
          },
          {
            type: "F",
            score: 5,
            content: "직접적으로 말하면 친구가 그래 할까봐 돌려 말한다."
          },
        ]
      },
      {
        which: "JP",
        question: "준비물을 준비할 때 나는?",
        answers: [
          {
            type: "J",
            score: 2,
            content: "하루 전 날 미리 준비한다."
          },
          {
            type: "P",
            score: 5,
            content: "‘내일 챙겨야지’ 하다가 까먹고 놓고 오는 일이 많다."
          },
        ]
      },
      {
        which: "JP",
        question: "끝나고 집에 가서 공부하려 했는데, 친구들이 놀자고 붙잡는다. 나는?",
        answers: [
          {
            type: "J",
            score: 2,
            content: "계획에 없던 일인데…매우 당황스럽다."
          },
          {
            type: "P",
            score: 5,
            content: "오케이! 역시 계획대로 안 되는 것이 인생! 놀자!!!!"
          },
        ]
      },
      {
        which: "JP",
        question: "내가 가고싶은 여행지 스타일은?",
        answers: [
          {
            type: "J",
            score: 2,
            content: "내가 계획한대로! 계획적으로 일의 순서대로 진행하는 것을 좋아해!"
          },
          {
            type: "P",
            score: 5,
            content: "그때그때 생각나는 대로 일 처리! 융통성 있게 하는 것을 좋아해!"
          },
        ]
      },
    ],
    results: [
      {
        type: "ESTJ",
        desc: ``,
        query: "ESTJ",
        // score_range: range(26),
        img_src: 'https://images.ktestone.com/resultImages/personalColor/ESTJ.png'
      },
      {
        type: "ESTP",
        desc: ``,
        query: "ESTP",
        // score_range: range(26, 51),
        img_src: 'https://images.ktestone.com/resultImages/personalColor/ESTP.png'
      },
      {
        type: "ESFJ",
        desc: ``,
        query: "ESFJ",
        // score_range: range(51, 75),
        img_src: 'https://images.ktestone.com/resultImages/personalColor/ESFJ.png'
      },
      {
        type: "ESFP",
        desc: ``,
        query: "ESFP",
        // score_range: range(76, 101),
        img_src: 'https://images.ktestone.com/resultImages/personalColor/ESFP.png'
      },
      {
        type: "ENTJ",
        desc: ``,
        query: "ENTJ",
        // score_range: range(76, 101),
        img_src: 'https://images.ktestone.com/resultImages/personalColor/ENTJ.png'
      },
      {
        type: "ENTP",
        desc: ``,
        query: "ENTP",
        // score_range: range(26),
        img_src: 'https://images.ktestone.com/resultImages/personalColor/ENTP.png'
      },
      {
        type: "ENFJ",
        desc: ``,
        query: "ENFJ",
        // score_range: range(26, 51),
        img_src: 'https://images.ktestone.com/resultImages/personalColor/ENFJ.png'
      },
      {
        type: "ENFP",
        desc: ``,
        query: "ENFP",
        // score_range: range(51, 75),
        img_src: 'https://images.ktestone.com/resultImages/personalColor/ENFP.png'
      },
      {
        type: "ISTJ",
        desc: ``,
        query: "ISTJ",
        // score_range: range(76, 101),
        img_src: 'https://images.ktestone.com/resultImages/personalColor/ISTJ.png'
      },
      {
        type: "ISTP",
        desc: ``,
        query: "ISTP",
        // score_range: range(76, 101),
        img_src: 'https://images.ktestone.com/resultImages/personalColor/ISTP.png'
      },
      {
        type: "ISFJ",
        desc: ``,
        query: "ISFJ",
        // score_range: range(76, 101),
        img_src: 'https://images.ktestone.com/resultImages/personalColor/ISFJ.png'
      },
      {
        type: "ISFP",
        desc: ``,
        query: "ISFP",
        // score_range: range(26),
        img_src: 'https://images.ktestone.com/resultImages/personalColor/ISFP.png'
      },
      {
        type: "INTJ",
        desc: ``,
        query: "INTJ",
        // score_range: range(26, 51),
        img_src: 'https://images.ktestone.com/resultImages/personalColor/INTJ.png'
      },
      {
        type: "INTP",
        desc: ``,
        query: "INTP",
        // score_range: range(51, 75),
        img_src: 'https://images.ktestone.com/resultImages/personalColor/INTP.png'
      },
      {
        type: "INFJ",
        desc: ``,
        query: "INFJ",
        // score_range: range(76, 101),
        img_src: 'https://images.ktestone.com/resultImages/personalColor/INFJ.png'
      },
      {
        type: "INFP",
        desc: ``,
        query: "INFP",
        // score_range: range(76, 101),
        img_src: 'https://images.ktestone.com/resultImages/personalColor/INFP.png'
      },
    ]
  },
]

export default ITEMS;
